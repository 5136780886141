import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        missionVission: "Vision & Mission",
        theBrainBehind: "The Mind Behind IB",
        meetTheLeaders: "Meet The Team",
        homeVisionTitle: "IB VISION",
        homeContainerOneShow: "See",
        homeContainerOneShowLess: "Less",
        homeContainerOneShowMore: "More",
        homeMissionTitle: "IB MISSION",
        serviceHeading: "What We Do",
        serviceHeadingSecondary: "Our Services",
        learnMore: "Learn More",
        phoneLabel: "Phone: ",
        emailLabel: "E-Mail: ",
        officeLabel: "Office address: ",
        officeAddressValue: "Wadi Elhaj St. Nazareth, Israel",
        btnSendLabel: "Send",
        nameLabel: "Your Name",
        footerEmailLabel: "Email Address",
        footerMessageLabel: "Your Message",
        socialMediaLabel:
          "Follow us on social media to stay updated with our latest projects, industry insights, and company news.",
        missionVissionHeading: "VISION AND MISSION",
        visionLabel: "IB VISION",
        visionTextOne:
          // "At IB ENG, we are your one-stop-shop for all aspects of the construction process. We offer a full range of services that start with sourcing and checking the potential of the land, all the way through planning, management, construction, and even interior design.",
          "To be the premier engineering boutique known for transforming complex challenges into innovative solutions that create lasting value for our clients and communities",
        visionTextTwo: "",
          // "Our team of professionals is there for you every step of the way, providing customized and creative engineering solutions that meet your specific needs. We are committed to staying up-to-date with the latest technology and using cutting-edge tools like Building Information Modeling (BIM)to streamline the construction process and increase efficiency.",
        visionTextThree: "",
          // "Our approach is focused on finding creative solutions that optimize both the construction and operational aspects of each project, ultimately saving our clients time and money. ",
        visionTextFour: "",
          // "At IB ENG, we take pride in our ability to provide comprehensive and personalized service to each and every one of our clients. Whether you are looking to build a new home, renovate an existing building, or undertake a commercial project, we are here to help you achieve your goals.",
        missionTitle: "IB MISSION",
        missionTextOne:
        "At IB Engineering, our mission is to deliver engineering solutions that exceed client expectations through careful planning, advanced technology, and a commitment to excellence. We work closely with our clients to turn their visions into reality by providing comprehensive services from land assessment to project completion, ensuring efficiency and unparalleled quality.",
          // "At IB Engineering, our mission is to serve as your comprehensive partner in construction, offering a full spectrum of services that seamlessly guide your projects from inception to realization. We embark on this mission by beginning with thorough land assessment, ensuring that your chosen site aligns perfectly with your vision",
        missionTextTwo: "",
          // "We embark on this mission by beginning with thorough land assessment, ensuring that your chosen site aligns perfectly with your vision. From there, our dedicated team employs the latest advancements in technology to craft meticulous plans that optimize efficiency and precision.",
        missionTextThree: "",
          // `Our commitment extends beyond the drawing board, we actively manage every aspect of your project, keeping a keen eye on both construction and operational elements.`,
        missionTextFour: "",
          // "At IB Engineering, we take immense pride in offering personalized service and expertise tailored to your unique needs. Whether you aspire to build a new home, breathe new life into an existing structure or embark on a commercial venture, we are your perfect partner, ready to transform your construction dreams into reality",
        whatWeDoTitleOne: "Build.",
        whatWeDoTitleTwo: "Solve.",
        whatWeDoTitleThree: "Evolve.",
        whatWeDoContainerTitle: "PLANNING SERVICES",
        whatWeDoContainerOne:
          "Our Planning Services offer comprehensive support for both developers and individuals seeking to build their dream projects.",
        whatWeDoContainerTwo:
          "We provide a team of expert planning engineers who can oversee every aspect of your project.",
        whatWeDoContainerThree:
          "From conceptualization to final plans, our professionals ensure that all necessary engineering components are meticulously designed, making your vision a reality.",
        whatWeDoContainerFour: "BUILDING INSPECTION",
        whatWedoContainerFive: `As part of our commitment to planning and building management, we offer Building Inspection services that ensure the highest standards of construction quality.`,
        whatWedoContainerSix: `Our qualified inspectors meticulously assess every detail of your project to guarantee that it meets industry regulations and your expectations.`,
        whatWedoContainerSeven: `With our expertise, you can confidently achieve superior building quality`,
        whatWedoContainerEight: "INTERIOR DESIGN",
        whatWedoContainerNine:
          "Transform your space into a masterpiece with our dedicated Interior Design department.",
        whatWedoContainerTen:
          "Our team of creative experts collaborates with you to bring your design aspirations to life.",
        whatWeDoContainerEleven:
          "Whether it's a residential or commercial project, our Interior Design services promise to create a harmonious and captivating environment that aligns with your vision.",
        whatWeDoTwelve: "ENGINEERING CONSULTATION",
        whatWeDoContainerThirteen:
          "Tap into our wealth of experience with our Engineering Consultation services.",
        whatWeDoContainerFourteen:
          "Whether you're embarking on a new project or seeking insights to enhance existing plans, our consultants offer expert guidance.",
        whatWeDoContainerFifteen:
          "From project conceptualization to cost analysis, our consultancy empowers you to navigate your project successfully and achieve your objectives.",
        theMindBehindeTitleOne: "THE MIND BEHIND",
        theMindBehindeTitleTwo: "Issa Bishara",
        theMindBehindSubTitleOne:
          "At the core of IB Engineering is a visionary leader whose expertise and commitment have shaped the company's outstanding reputation. Our founder, a highly experienced construction engineer, holds a Master's degree in building engineering with specializations in earthquake resilience and traffic management. Registered as an engineer since 1979, his career has been a journey of significant contributions to the industry, with a deep focus on innovation and excellence in every project he undertakes.",
        theMindBehindSubTitleTwo:
          "From 1992 to 2016, Issa served as the city engineer of Nazareth, playing a pivotal role in the city's development. His influence extends beyond the local level, contributing to national infrastructure projects. As a member of steering committees for the Ministry of Transportation and the Ministry of Construction and Housing, he has helped shape the future of transportation and housing in Israel.",
        theMindBehindSubTitleTHree: `His leadership has been instrumental in numerous key projects, including public transportation planning, mass transit systems in Haifa, and transportation master plans for northern Israel. His ability to lead, plan, and execute complex projects has earned him recognition as a key figure in the engineering world. He is known for his meticulous attention to detail and unwavering commitment to quality.`,
        theMindBehindSubTitleFour: `Engineering Excellence, Forging Tomorrow's Innovation Today, in the Heart of Israel.`,

        theMindBehindSubTitleFive: `Beyond his professional achievements, our founder is driven by a deep passion for engineering and a steadfast commitment to client satisfaction. He approaches each project with a focus on creativity, efficiency, and a comprehensive understanding of construction needs. This holistic approach ensures that every project is not only built but carefully crafted to meet the highest standards of excellence.`,
        theMindBehindSubTitleSix: `With over four decades of experience, he continues to push the boundaries of engineering, staying ahead of technological advancements and emerging methodologies. His dedication to innovation is evident in the solutions he develops, consistently surpassing client expectations and setting new industry standards.`,
        theMindBehindSubTitleSeven: `Outside of his professional life, our founder enjoys hobbies that fuel his creativity, including traveling, gardening, and Sudoku. He also finds great joy in teaching and mentoring new employees, helping to build his legacy and ensure the continued excellence of IB Engineering.`,
        teamTitle: "MEET THE TEAM",
        leaderOneMainText: "Development Department Manager",
        leaderTextOne:
          "Alaa Bishara is a seasoned real estate developer, blockchain enthusiast, and tech entrepreneur based between Israel and Dubai. With dual degrees in Law and Business from Reichman University and an MBA in Real Estate, Alaa has co-founded multiple ventures, including a successful digital marketing company and Aask investments, specializing in urban renewal projects. As a native speaker of Hebrew, Arabic, and English, Alaa leverages his linguistic and cultural fluency to open new doors and create opportunities in diverse markets.",
        leaderTextTwo: "",
          // "With a diverse educational background, including a bachelor's degree in law and business administration from Reichman University and a master's degree in business administration specializing in real estate and appraisals, Alaa brings a unique blend of skills to the team.",
        leaderTextThree: "",
          // "As a serial founder, entrepreneur, and VP of sales, he has played pivotal roles in several successful companies. His visionary leadership and extensive experience contribute significantly to IB Engineering's growth and success.",
       
          leaderTwoMainText: "Head of Interior Design Department",
        leaderTextTwoOne:
          "As the dedicated leader of our Interior Design Department, Rawan Bishara Sabbah brings a wealth of expertise and creativity to IB Engineering. A distinguished graduate of the Technion with a degree in civil engineering, specializing in structures, and a passion for interior design, Rawan has been a registered engineer since 2009. With over 15 years of experience, she has led numerous high-profile projects, particularly in residential towers across central and northern Israel. Rawan's passion for art, fashion, and refined taste brings innovation to every project, elevating them to new heights of excellence.",
        leaderTextTwoTwo: "",
          // "A distinguished alumna of the Technion with a degree in civil engineering, specialized in structures, and honored in interior design, Rawan has been a registered engineer and architect since 2009.",
        leaderTextTwoThree: "",
          // "With over 15 years of experience, she has been the guiding force behind numerous construction and interior design projects, particularly in residential towers across central and northern regions.",
        leaderTextTwoFour:
          "Rawan's passion for art, fashion, and a discerning taste infuse her work with creativity and innovation, elevating every project to new heights of excellence.",
        
        leaderThreeMainText: "Head of Planning Department",
        leaderTextThreeOne:
          "Daher is a rising star at IB Eng. having begun his journey as an intern and progressing to become a licensed engineer under the mentorship of our founder. His youthful energy, coupled with a deep well of experience, drives the planning department's unwavering commitment to excellence. Daher's visionary leadership and collaborative approach inspire our team to meticulously plan and execute projects with precision, consistently reaching new heights in construction planning.",
        leaderTextThreeTwo: "",
          // "His youthful energy and extensive experience drive our planning department's commitment to excellence, ensuring that each project is meticulously planned and executed with precision. ",
        leaderTextThreeThree: "",
          // "Mohammad's visionary leadership and collaborative. Approach inspire our team to achieve new heights in the world of construction planning",
        
        
          protfoliHeadingOne: "National Leader in",
        protfoliHeadingTwo: "Engineering",
        protfoliSubHeadingOne: "PORTFOLIO",
        protfoliSubHeadingTwo: "Our Projects",
        whatOurClientSays: "What our clients say",
        contactUsTitle: "Get in Touch",
        contactSubTitleOne:
          "Do you have a specific inquiry or want to discuss a potential project? Fill out the form below, and one of our representatives will get back to you as soon as possible.",
        contactSubTitleTwo: `Fill out the form when you click on “Contact Us” button, and one of our representatives will get back to you promptly. 
        We appreciate your interest in IB Engineering, and we look forward to working with you to turn your construction and engineering visions into reality.`,
        contactUsBtnText: "Contact Us",
        allRightsReserved: "All Rights Reserved",
        contactUsTitleTwo: "Contact",
        contactInputName: "Name",
        contactInputEmail: "E-mail",
        contactInputMobile: "Phone",
        contactInputMessage: "Message",
        contactFormError: "All fields are required !",
        contactformFailure: "There is some issue with the server. Please try again.",
        contactNameRequired: "Name is required !",
        contactEmailRequired: "Email is required !",
        contactNotEmailRequired: "Not an Email ",
        contactMessageRequired: "Message is required !",
        contactUsSubmitBtn: "Send",
        contactUsSubmitBtnSuccess: "Sent",
        mobileValidationMsg: "Not a valid Mobile number",
        emptyMobileNumber: "Mobile number is required",
        meetingTime: "Activity Time: ",
        meetingTimeText: `Monday - Friday: 8:00 AM - 16:00 PM`,
        followMsg:
          "Follow us on social media to stay updated on our latest projects:",
        languageLabel: "Select Language",
        menuOption1: "Mission and Vision",
        menuOption2: "Mission and Vision",
        menuOption3: "Mission and Vision",
      },
    },
    // send footer btn שלח
    //sent footer btn נשלח
    he: {
      translation: {
        missionVission: "חזון ומשימה",
        theBrainBehind: "המוח מאחורי איי.ביי",
        meetTheLeaders: "פגוש את הצוות",
        homeVisionTitle: " חזון",
        homeContainerOneShow: "ראה",
        homeContainerOneShowLess: " פחות",
        homeContainerOneShowMore: " עוד",
        homeMissionTitle: "משימה",
        serviceHeading: "מה אנחנו עושים",
        serviceHeadingSecondary: "השירותים שלנו",
        learnMore: "ראה עוד",
        phoneLabel: " טלפון ",
        emailLabel: "דואר אלקטרוני",
        officeLabel: "כתובת משרד",
        officeAddressValue: "ואדי אלחאג' רח׳ נצרת, ישראל",
        btnSendLabel: "שלח",
        nameLabel: "שם",
        footerEmailLabel: "דואר אלקטרוני",
        footerMessageLabel: "הודעה",
        socialMediaLabel:
          "עקבו אחרינו ברשתות החברתיות כדי להישאר מעודכנים בפרויקטים האחרונים שלנו:",
        missionVissionHeading: "החזון והמשימה",
        visionLabel: "חזון",
        visionTextOne:
        `להיות חברת הבוטיק המובילה בהנדסה, המוכרת בזכות יכולתה להפוך אתגרים מורכבים לפתרונות חדשניים שיוצרים ערך מתמשך ללקוחותינו ולקהילות`,
        visionTextTwo: "",
        // `אנו מחויבים להישאר מעודכנים בטכנולוגיה העדכנית ביותר
        // ולהשתמש בכלים מתקדמים כמו Building Information
        // Modeling (BIM) כדי לייעל את תהליך הבנייה ולהגביר את
        // היעילות`,
        visionTextThree: "",
        // `הגישה שלנו מתמקדת במציאת פתרונות יצירתיים המייעלים
        // הן את ההיבטים הבנייה והן התפעוליים של כל פרויקט,
        // ובסופו של דבר חוסכים ללקוחותינו זמן וכסף`,
        visionTextFour: "",
        // `ב-איי.ביי הנדסה, אנו גאים ביכולתנו לספק שירות מקיף
        // ומותאם אישית לכל אחד ואחת מלקוחותינו. בין אם אתם
        // מחפשים לבנות בית חדש, לשפץ בניין קיים או לבצע פרויקט
        // מסחרי, אנחנו כאן כדי לעזור לכם להשיג את המטרות שלכם.`,
        missionTitle: "משימה",
        missionTextOne:`ב-איי.ביי הנדסה, המשימה שלנו היא לספק פתרונות הנדסיים שעולים על ציפיות הלקוחות באמצעות תכנון קפדני, טכנולוגיה מתקדמת ומחויבות למצוינות. אנו עובדים בשיתוף פעולה הדוק עם לקוחותינו כדי להפוך את החזון שלהם למציאות על ידי מתן שירותים מקיפים מהערכת קרקע ועד להשלמת הפרויקט, תוך הבטחת יעילות ואיכות ללא תחרות`,
        missionTextTwo: "",
        // `ב-איי.ביי הנדסה משימתנו היא לשמש שותף מקיף בתחום הבנייה תוך הצעת מגוון שירותים המנחים את הפרויקטים שלך מהרגע הראשון ועד להגשמתם`,
        missionTextThree: "",
        // `שם, צוותנו המסור מעסיק את ההתקדמויות העדכניות
        // ביותר בטכנולוגיה כדי ליצור תוכניות מדוקדקות
        // הממקסמות יעילות ודיוק. התחייבותנו נטויה מעבר
        // ללוח השרטוטים, אנו ניהולם באופן פעיל לכל פקטור
        // בפרויקט שלך, תוך שמירה על עין חדה הן על אלמנטים
        // של הבנייה והן על ההיבטים התפעוליים`,
        missionTextFour: "",
        // `נו מתגאים באופן עצום בהצעת שירות אישי ומומחיות
        // המותאמים לצרכים הייחודיים שלך בין אם אתה שואף
        // לבנות בית חדש, להנשים חיים חדשים במבנה קיים או
        // להתחיל ביוזמה מסחרית, אנו השותף המושלם עבורך,
        // מוכנים להפוך את חלומות הבנייה שלך למציאות`,
        whatWeDoTitleOne: "לתכנן",
        whatWeDoTitleTwo: "לבנות",
        whatWeDoTitleThree: "להתפתח",
        whatWeDoContainerTitle: "שירותי תכנון",
        whatWeDoContainerOne: `חברתנו מתמחה בתכנון קונסטרוקציה ותנועה, אנו מציעים תמיכה מקיפה ליזמים ויחידים שמטרתם להגשים את החזונות שלהם.`,
        whatWeDoContainerTwo: `צוות מהנדסי התכנון המומחים שלנו מצטיין ביצירת תוכניות בנייה מדוקדקות ועומדות בסטנדרטים הגבוהים של התעשייה`,
        whatWeDoContainerThree:
          "מעבר לתכנון קונסטרוקציה ותנועה אנו מעניקים גם סיוע בהרכבת `כל היועצים הנדרשים לתכנן פרויקט החלומות שלכם.",
        whatWeDoContainerFour: `פיקוח צמוד`,
        whatWedoContainerFive: `כחלק מהמחויבות שלנו לתכנון וניהול בניה, אנו מציעים שירותי פיקוח צמוד לבניה שמבטיחים את הסטנדרטים הגבוהים ביותר של איכות הבנייה`,
        whatWedoContainerSix: `המפקחים המוסמכים שלנו מעריכים בקפדנות כל פרט בפרויקט שלך כדי להבטיח שהוא עומד בתקנות התעשייה ובציפיות שלך.`,
        whatWedoContainerSeven: `עם המומחיות שלנו, אתה יכול להשיג בביטחון איכות בנייה מעולה`,
        whatWedoContainerEight: "עיצוב פנים",
        whatWedoContainerNine:
          "הפוך את החלל שלך ליצירת מופת עם מחלקת עיצוב הפנים הייעודית שלנו",
        whatWedoContainerTen:
          "צוות המומחים היצירתיים שלנו משתף איתך פעולה כדי להחיות את שאיפות העיצוב שלך",
        whatWeDoContainerEleven:
          " בין אם מדובר בפרויקט מגורים או מסחרי, שירותי עיצוב הפנים שלנו יוצר סביבה הרמונית שמתיישרת עם החזון שלך",
        whatWeDoTwelve: "ייעוץ הנדסי",
        whatWeDoContainerThirteen:
          "נצל את הניסיון העשיר שלנו עם שירותי הייעוץ ההנדסי",
        whatWeDoContainerFourteen:
          " בין אם אתה יוצא לפרויקט חדש או מחפש תובנות לשיפור התוכניות הקיימות, היועצים שלנו מציעים הכוונה מומחית",
        whatWeDoContainerFifteen:
          " מהמשגה של פרויקט ועד לניתוח עלויות, הייעוץ שלנו מאפשר לך לנווט בפרויקט שלך בהצלחה ולהשיג את היעדים שלך",
        theMindBehindeTitleOne: "המוח מאחורי",
        theMindBehindeTitleTwo: "איי.ביי הנדסה",
        theMindBehindSubTitleOne:
          "במרכז איי.ביי הנדסה עומד מנהיג בעל חזון, שהמומחיות והמחויבות שלו עיצבו את המוניטין המצוין של החברה. המייסד שלנו הוא מהנדס בניין מנוסה ביותר. עם תואר שני בהנדסת בניין והתמחות ברעידות אדמה ובהנדסת תחבורה.",
        theMindBehindSubTitleTwo:
          "מאז שנרשם בפנקס המהנדסים בשנת 1979, הקריירה שלו מהווה עדות להשפעתו המשמעותית על התעשייה. בין השנים 1992 ל-2016, שימש כמהנדס העיר נצרת, ושיחק תפקיד מרכזי בפיתוח העיר. השפעתו חורגת מהתחום המקומי לפרויקטים לאומיים, בהן השתתף בוועדות היגוי מטעם משרד התחבורה ומשרד הבינוי והשיכון.",
        theMindBehindSubTitleTHree: `מנהיגותו הייתה גורם מכריע בפרויקטים מרכזיים רבים, כולל תכנון תחבורה ציבורית, מערכות תחבורה ציבורית במטרופולין חיפה ותוכניות אב לתחבורה בצפון ישראל. יכולתו להוביל, לתכנן ולבצע פרויקטים מורכבים הפכה אותו לדמות מפתח בעולם ההנדסה.`,
        theMindBehindSubTitleFour: `מעבר להישגיו המקצועיים, המייסד שלנו מונע מתשוקה עמוקה להנדסה וממחויבות איתנה לסיפוק הלקוחות. הוא ניגש לכל פרויקט בדגש על יצירתיות, יעילות והבנה מקיפה של צורכי הבנייה. גישה הוליסטית זו מבטיחה שכל פרויקט אינו רק נבנה, אלא מתוכנן בקפידה כדי לעמוד בסטנדרטים הגבוהים ביותר.`,
        theMindBehindSubTitleFive: `עם למעלה מארבעה עשורים של ניסיון, הוא ממשיך לדחוף את גבולות ההנדסה, תוך שמירה על עדכניות בטכנולוגיות ושיטות עבודה חדשות. מחויבותו לחדשנות ניכרת בפתרונות שהוא מפתח, העולים בעקביות על ציפיות הלקוחות.`,
        theMindBehindSubTitleSix: `מחוץ לחייו המקצועיים, המייסד שלנו נהנה ממגוון תחביבים שמזינים את היצירתיות שלו, כולל טיולים, גינון ומשחקי סודוקו. בנוסף, הוא נהנה ללמד ולהעביר את הידע שלו לעובדיו החדשים, מתוך רצון לבנות את המורשת שלו ולהבטיח את המשך ההצטיינות של החברה.`,
        theMindBehindSubTitleSeven: `לסיכום, מורשתו של המייסד שלנו היא של חדשנות, מצוינות ומחויבות עמוקה לאמנות ולמדע ההנדסה. מנהיגותו לא רק מגדירה את איי.ביי הנדסה, אלא גם מציבה סטנדרטים חדשים בתעשייה, מה שהופך אותו לנכס חיוני בכל פרויקט שהוא מוביל.`,
        teamTitle: "להכיר את הצוות",
        leaderOneMainText: "מנהל מחלקת פיתוח",
        leaderTextOne: `עלאא בשארה הוא מפתח נדל"ן מנוסה, חובב בלוקצ'יין ויזם טכנולוגי הממוקם בין ישראל לדובאי. עם רקע חזק במשפטים ומנהל עסקים מהמרכז הבינתחומי בהרצליה ותואר שני במנהל עסקים עם התמחות בנדל"ן, עלאא שותף להקמת מספר מיזמים, כולל חברת שיווק דיגיטלי מצליחה ואאסק להשקעות, המתמחה בפרויקטים של התחדשות עירונית. כשפה אם בעברית, בערבית ובאנגלית, עלאא מנצל את השפה והתרבות שלו כדי לפתוח דלתות חדשות וליצור הזדמנויות בשווקים מגוונים.`,
        leaderTextTwo: "", //`עיסא היה גם שותף מרכזי בצוותים לעריכת תיקי הנחיות לתכנון נתיבי תחבורה ציבורית, מערכות הסעת המונים במטרופולין חיפה, תוכניות אב תחבורתיות למחוזות חיפה והצפון ופרויקטים של תשתית גדולים נוספים. עם עשרות שנות ניסיון.`,
        leaderTextThree: "", //`עיסא בשארה תמיד היה נלהב מעבודתו בתחום ההנדסה והבנייה. יש לו התמקדות חזקה ביצירתיות, יעילות ושביעות רצון לקוחות. בנוסף, יש לו מגוון רחב של תחביבים ותחומי עניין, כמו טיולים, סודוקו וגינון.`,
        leaderTwoMainText: "ראש מחלקת עיצוב פנים",
        leaderTextTwoOne:
        `כמנהלת המסורה של מחלקת עיצוב הפנים, ראוון בשארה סבאח מביאה עמה שילוב מרשים של ידע ויצירתיות ל-איי.ביי הנדסה. בוגרת מצטיינת מהטכניון עם תואר בהנדסה אזרחית, התמחות במבנים ותשוקה לעיצוב פנים, ראוון רשומה כמהנדסת מאז 2009. עם למעלה מ-15 שנות ניסיון, היא הובילה פרויקטים רבים בעלי פרופיל גבוה, בעיקר במגדלי מגורים באזורי המרכז והצפון. התשוקה שלה לאמנות, אופנה וטעם מעודן מביאה חדשנות לכל פרויקט, ומרימה אותם לגבהים חדשים של מצוינות.`,
          // "מומחד הוא כוכב עולה ב-איי.ביי הנדסה. החל כמתמחה, היום הוא מהנדס רשוי, הוא חידד את כישוריו בהנחיית המייסד שלנו",
        leaderTextTwoTwo: "",
          // " האנרגיה הצעירה שלו וניסיונו הרב מניעים את המחויבות של מחלקת התכנון שלנו למצוינות, ומבטיחים שכל פרויקט מתוכנן בקפידה ומבוצע בדייקנות",
        leaderTextTwoThree: "",
          // " המנהיגות בעלת החזון והגישה השיתופית של מוחמד נותנת השראה לצוות שלנו להגיע לשיאים חדשים בעולם תכנון הבנייה",
        leaderThreeMainText: "ראש מחלקת עיצוב פנים",
        leaderTextThreeOne: `דאהר הוא כוכב עולה ב-איי.ביי הנדסה, שהתחיל את דרכו כמתמחה והתקדם להיות מהנדס מוסמך תחת הנחייתו של המייסד שלנו. האנרגיה הצעירה שלו בשילוב עם הניסיון הרב שהוא צבר, מניעה את המחלקה לתכנון לעמידה בסטנדרטים גבוהים של מצוינות. מנהיגותו החזונית והגישה השיתופית של דאהר מעוררות השראה בצוות שלנו לתכנן ולבצע פרויקטים בקפדנות ובדיוק, תוך השגת גבהים חדשים בתחום תכנון הבנייה.`,
          // "בתור המנהיגה המסורה של מחלקת עיצוב הפנים שלנו רואן בשארה סבאח מביאה שפע של מומחיות",
        leaderTextThreeTwo: "",
          // "בוגרת מכובדת של הטכניון, בעלת תואר בהנדסה אזרחית",
        leaderTextThreeThree: "",
          // "מתמחה במבנים ותעודה בעיצוב פנים רואן היא מהנדסת רשומה משנת 2009",
        leaderTextThreeFour: "",
        // "עם ניסיון של למעלה מ-15 שנים, התשוקה של רואן לאמנות, אופנה מעלה כל פרויקט לשיאים חדשים של מצוינות יצירתיות וחדשנות",
        protfoliHeadingOne: "חברה",
        protfoliHeadingTwo: "מובילה",
        protfoliHeadingThree: "בתחום ההנדסה",
        protfoliSubHeadingOne: "פורטפוליו",
        protfoliSubHeadingTwo: "פרוייקטים",
        whatOurClientSays: "ממליצים עלינו",
        contactUsTitle: "ליצור קשר",
        contactSubTitleOne: `יש לך בירור ספציפי או רוצה לדון בפרויקט פוטנציאלי? מלא את הטופס למטה, ואחד מנציגינו יחזור אליך בהקדם`,
        contactSubTitleTwo: `תודה ששקלת את איי.ביי הנדסה לצרכי התכנון או הבנייה שלך
        . אנו נמצאים כאן כדי להתחבר אליך ולחקור כיצד נוכל לשתף פעולה בפרויקט הבא שלך. בין אם יש לך שאלות, זקוק לסיוע או מוכן להתחיל, אנחנו כאן בשבילך.`,
        contactUsBtnText: `צור קשר`,
        allRightsReserved: "כל הזכויות שמורות",
        contactUsTitleTwo: "צור קשר",
        contactInputName: "שם",
        contactInputEmail: "דואר אלקטרוני",
        contactInputMobile: "טלפון",
        contactInputMessage: "הודעה",
        contactFormError: "כל הטעמים נדרשים",
        contactformFailure: "יש בעיה כלשהי בשרת. אנא נסה שוב.",
        contactNameRequired: "נדרש שם",
        contactEmailRequired: "יש צורך במייל",
        contactNotEmailRequired: "לא אימייל",
        contactMessageRequired: "נדרשת הודעה",
        contactUsSubmitBtn: "שלח",
        contactUsSubmitBtnSuccess: "נשלח",
        mobileValidationMsg: "לא מספר נייד חוקי",
        emptyMobileNumber: "נדרש מספר נייד",
        meetingTime: ":שעות פעילות",
        meetingTimeText: `PM 16:00 -AM 8:00:שני - שישי`,
        followMsg:
          ":עקבו אחרינו ברשתות החברתיות כדי להישאר מעודכנים בפרויקטים האחרונים שלנו",
        languageLabel: "בחר שפה",
      },
    },
  },
});

export default i18n;
